import ApexCharts from "apexcharts"
import $ from 'jquery';

const generateOptions = (
    chartTitle = 'Missing Title',
    dataPoints = [],
    xAxisCategories = [],
    showYAxis = true,
    customTitle = 'RODS historie'
) => ({
    series: [{
        name: chartTitle,
        data: dataPoints
    }],
    chart: {
        type: 'line',
        stacked: false,
        height: 350,
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
        },
        toolbar: {
            autoSelected: 'zoom'
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    markers: {
        size: 0,
    },
    title: {
        text: customTitle,
        align: 'left'
    },
    yaxis: showYAxis
        ? {
            title: { text: 'Amount in RODS' },
            labels: { show: false }
        }
        : {
            labels: { show: false }
        },
    xaxis: {
        categories: xAxisCategories
    },
    tooltip: {
        shared: false,
    }
});

$('.js-chart').each(function () {
    const $this = $(this)
    const xAxisCategories = JSON.parse(this.dataset.xaxisCategories);
    const dataPoints = JSON.parse(this.dataset.data);
    const chartTitle = this.dataset.title;
    const chartType = this.dataset.chartType || "default";

    const isMultipleChoice = chartType === "multiple-choice";
    const showYAxis = !isMultipleChoice;
    const customTitle = isMultipleChoice ? chartTitle : 'RODS historie';
    const chart = new ApexCharts(this, generateOptions(chartTitle, dataPoints, xAxisCategories, showYAxis, customTitle));
    chart.render();
});
