import Sortable from 'sortablejs';

const createSortable = (element, onEndCallback) => new Sortable(element, {
        group: element.dataset.sortableGroup ?? 'shared',
        handle: element.dataset.sortableHandle ?? '.fa-arrows-alt',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        // get sorting weights after drag and drop
        onEnd: function (sortableEvent) {
            // -- NOTE: ond end we want to update the sorting weights
            // -- we should keep track of this in a hidden input field
            onEndCallback?.(sortableEvent)
        }
    });


export default createSortable;
