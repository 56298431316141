import $ from 'jquery';

function debounce(fn, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(() => fn.apply(this, args), delay)
    };
}
const switcherClassname = '.js-switch-me-daddy';

const handleChange = function () {
    const $this = $(this);
    const isChecked = this.checked
    const $targetElement = $this.parent().find(switcherClassname)
    const icons = $targetElement.data('icons').split(',')
    $targetElement.removeClass(`${icons[0]} ${icons[1]}`)
    $targetElement.addClass(icons[isChecked * 1])
}


$(`${switcherClassname} + input[type=checkbox]`).each(handleChange)
$(`${switcherClassname} + input[type=checkbox]`).on('change', handleChange)

const $quickSearchEl = $('.js-quick-search');
const searchChangeHandler = debounce(function () {
    const $this = $(this);
    const query = $this.find('[name=query]').val().toLowerCase()
    const $targetTable = $($this.data('target'))
    const $rows = $targetTable.find('tbody tr');
    $rows.removeClass('d-none')

    if (query) {
        $rows.each(function () {
            const $row = $(this)
            let valueIncludesQuery = false;
            $row.find('input[type=text]').each(function () {
                const val = $(this).val().toLowerCase()
                if (val.includes(query)) {
                    valueIncludesQuery = true;
                }
            })
            if (!$row.text().toLowerCase().includes(query) && !valueIncludesQuery) {
                $row.addClass('d-none')
            }
        })
    }
}, 200)
$quickSearchEl.on('submit', function (e) { e.preventDefault() })
$quickSearchEl.on('keyup', searchChangeHandler);