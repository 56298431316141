import $ from 'jquery';

const $allInputsToBeUnchecked = $('.js-uncheck-all-parent input[type=checkbox]:not(.js-uncheck-all)')
const $inputUncheckAll = $('.js-uncheck-all')

$inputUncheckAll.on('change', function () {
    if (!this.checked)
        $allInputsToBeUnchecked.prop('checked', false);
})

$('.js-set-checkbox-to-checked').on('click', function () {
    $(this.dataset.target).prop('checked', true);
});


$('.menu-checkbox, .func-dropdown-checkbox-0').on('change', function () {
    document.cookie = `checkbox_${this.name}=${this.checked}; path=/`
});