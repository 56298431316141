import $ from 'jquery';

$tooltipsCheckboxParents = $('.func-tooltip-checkbox-parent');
if ($tooltipsCheckboxParents.length) {
    $tooltipsCheckboxes = $('.func-tooltip-checkbox');

    $(document).on('click', function ({ target }) {
        $tooltipsCheckboxes.each(function (index, checkBox) {
            if (!$.contains($tooltipsCheckboxParents[index], target) && target !== checkBox) 
                $(checkBox).prop('checked', false);
        });
    });
}