import $ from 'jquery';

$(document.body).on('click',  '.js-btn-danger', function(e) {
	const confirmationMessage = $(this).data("message") ?? "Are you sure?";
	const confirmation = confirm(confirmationMessage);
	if(!confirmation) {
		e.preventDefault();
		e.stopPropagation();
		return false;
	}
});