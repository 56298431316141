import $ from 'jquery'

$(document.body).on('click', '.js-toggler', function() {
    const $this = $(this)
    const $target = $($this.data('target'))
    const className = $this.data('className')
    const $focusTarget = $($this.data('focusTarget'))

    $target.toggleClass(className)
    $focusTarget.focus()
})