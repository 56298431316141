import './js/2fa';
import './js/alertButtons';
import './js/charts';
import './js/checkboxMagic';
import './js/ctrlPlusS';
import './js/custom';
import './js/menu';
import './js/multiLangSelect';
import './js/notifications';
import './js/sortable';
import './js/survey';
import './js/toggler';
import './js/tooltip';