import $ from 'jquery';
import createSortable from './_sortable';

const $createSurveyForm = $('.create-survey-form');
if ($createSurveyForm.length) {
    const $document = $(document.documentElement);
    const activeLanguage = $(document.body).data('activeLanguage');

    const $chapterSortableContainer = $('.chapter-sortable-container');

    // Focus on input field when display is toggled
    $document.on('change', '.func-title-input-checkbox-parent .func-title-input-checkbox', function () {
        $this = $(this);
        if ($this.prop('checked')) {
            const $titleInputCheckboxParent = $this.closest('.func-title-input-checkbox-parent');
            const selectedLanguage = $titleInputCheckboxParent.find('.language-select input[type=radio]:checked').val();
            $titleInputCheckboxParent.find(`.language-input input[data-language="${selectedLanguage}"]`).trigger("focus");
        }
    });

    // Update sortable titles when form titles change
    $document.on('blur', `.func-title-input-checkbox-parent .language-input input[type=text][data-language="${activeLanguage}"]`, function () {
        const $this = $(this);
        const value = $this.val();
        const $titleInputCheckboxTitle = $this.closest('.func-title-input-checkbox-parent').find('.func-title-input-title');
        $titleInputCheckboxTitle.text(value);

        const $chapterWrapper = $this.closest('.chapter-wrapper');
        const chapterIndex = $chapterWrapper.data('index');
        const $sectionWrapper = $this.closest('.section-wrapper');
        if ($sectionWrapper.length) {
            const sectionIndex = $sectionWrapper.data('index');
            const $chapterDraggableContainer = $($chapterSortableContainer.find('>.draggable-container')[chapterIndex]);
            const $draggableContainerLabel = $($chapterDraggableContainer.find('.js-section-draggable-label')[sectionIndex]);
            $draggableContainerLabel.text(value);
        } else {
            const $draggableContainerLabel = $($chapterSortableContainer.find('.js-chapter-draggable-label')[chapterIndex]);
            $draggableContainerLabel.text(value);
        }
    });

    $document.on('blur', `.question-wrapper .language-input input[data-language="${activeLanguage}"]`, function () {
        const $this = $(this);
        const value = $this.val();

        const $chapterWrapper = $this.closest('.chapter-wrapper');
        const chapterIndex = $chapterWrapper.data('index');
        const $sectionWrapper = $this.closest('.section-wrapper');
        const sectionIndex = $sectionWrapper.data('index');
        const $questionWrapper = $this.closest('.question-wrapper');
        const questionIndex = $questionWrapper.data('index');

        const $chapterDraggableContainer = $($chapterSortableContainer.find('>.draggable-container')[chapterIndex]);
        const $sectionDraggableContainer = $($chapterDraggableContainer.find('.section-sortable-container > .draggable-container')[sectionIndex]);
        const $draggableContainerLabel = $($sectionDraggableContainer.find('.js-question-draggable-label')[questionIndex]);
        $draggableContainerLabel.text(value);
    });

    /**
     * Update index functions
     */

    function updateLanguageSelectNames($element) {
        $element.find('.language-select').each(function (i, languageSelect) {
            const randomNumber = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
            $(languageSelect).find('input[type=radio]').attr('name', randomNumber);
        })
    }

    function updateAllIndexes() {
        $createSurveyForm.find('.chapter-wrapper').each(function (chapterIndex, chapterWrapper) {
            const $chapterWrapper = $(chapterWrapper);
            $chapterWrapper.data('index', chapterIndex);
            $chapterWrapper.find('.language-input input[type=text]').each(function (i, input) {
                const $input = $(input);
                const key = $input.data('key');
                const language = $input.data('language');
                $input.attr('name', `chapters[${chapterIndex}][${key}][${language}]`);
            });
            updateSectionIndexes($chapterWrapper);
        });

        $chapterSortableContainer.find('>.draggable-container', (chapterIndex, draggableContainer) => {
            draggableContainer.dataset.index = chapterIndex;
            $(draggableContainer).find('.section-sortable-container > .draggable-container').each((sectionIndex, sectionDraggableContainer) => {
                sectionDraggableContainer.dataset.index = sectionIndex;
                $(sectionDraggableContainer).find('.question-sortable-container > .draggable-container').each((questionIndex, questionDraggableContainer) => {
                    questionDraggableContainer.dataset.index = questionIndex;
                });
            });
        });
    }

    function updateSectionIndexes($chapterWrapper) {
        const chapterIndex = $chapterWrapper.data('index');
        $chapterWrapper.find('.section-wrapper').each(function (sectionIndex, sectionWrapper) {
            const $sectionWrapper = $(sectionWrapper);
            $sectionWrapper.data('index', `${sectionIndex}`);
            $sectionWrapper.find('.language-input input[type=text]').each(function (i, input) {
                const $input = $(input);
                const key = $input.data('key');
                const language = $input.data('language');
                $input.attr('name', `chapters[${chapterIndex}][sections][${sectionIndex}][${key}][${language}]`);
            });
            updateQuestionIndexes($sectionWrapper);
        });
    }

    function updateQuestionIndexes($sectionWrapper) {
        const chapterIndex = $sectionWrapper.closest('.chapter-wrapper').data('index');
        const sectionIndex = $sectionWrapper.data('index');
        $sectionWrapper.find('.question-wrapper').each(function (questionIndex, questionWrapper) {
            const $questionWrapper = $(questionWrapper);
            $questionWrapper.data('index', questionIndex);
            $questionWrapper.find('.language-input input[type=text]').each(function (i, input) {
                const $input = $(input);
                const key = $input.data('key');
                const language = $input.data('language');
                $input.attr('name', `chapters[${chapterIndex}][sections][${sectionIndex}][questions][${questionIndex}][${key}][${language}]`);
            });
            updateAnswerIndexes($questionWrapper);
        });
    }

    function updateAnswerIndexes($questionWrapper) {
        const chapterIndex = $questionWrapper.closest('.chapter-wrapper').data('index');
        const sectionIndex = $questionWrapper.closest('.section-wrapper').data('index');
        const questionIndex = $questionWrapper.data('index');
        $questionWrapper.find('.answer-wrapper .language-input').each(function (answerIndex, languageInputWrapper) {
            const $languageInputWrapper = $(languageInputWrapper);
            let inputName = `chapters[${chapterIndex}][sections][${sectionIndex}][questions][${questionIndex}][answers][${answerIndex}]`;
            $languageInputWrapper.find('input[type=text]').each(function (i, input) {
                const $input = $(input);
                const key = $input.data('key');
                const language = $input.data('language');
                input.name = inputName + `[${key}][${language}]`;
            });
        });
    }

    /**
     * Add/Delete buttons
     */

    $document.on('click', '.js-add-chapter-button', function () {
        const $clonedTemplateContent = $('#chapter-wrapper-template').contents().clone();
        $createSurveyForm.append($clonedTemplateContent);

        const $clonedDraggableTemplateContent = $('#chapter-draggable-container-template').contents().clone();
        $chapterSortableContainer.append($clonedDraggableTemplateContent);

        updateAllIndexes();
    });

    $document.on('click', '.js-delete-chapter-button', function () {
        const $this = $(this);
        const $chapterDraggableContainer = $this.closest('.draggable-container');
        const chapterIndex = $chapterDraggableContainer.data('index');

        $chapterDraggableContainer.remove();
        $createSurveyForm.find('.chapter-wrapper')[chapterIndex].remove();
        $createSurveyForm.find('> hr')[chapterIndex + 1].remove();

        updateAllIndexes();
    });

    $document.on('click', '.js-add-section-button', function () {
        const $this = $(this);
        const $clonedTemplateContent = $('#section-wrapper-template').contents().clone();
        $clonedTemplateContent.insertBefore($this);

        const $chapterWrapper = $this.closest('.chapter-wrapper');
        const chapterIndex = $chapterWrapper.data('index');
        const $clonedDraggableTemplateContent = $('#section-draggable-container-template').contents().clone();

        const $chapterDraggableContainers = $chapterSortableContainer.find('>.draggable-container');
        const $chapterDraggableContainer = $($chapterDraggableContainers[chapterIndex]);
        const $sectionSortableContainer = $chapterDraggableContainer.find('.section-sortable-container');
        $sectionSortableContainer.append($clonedDraggableTemplateContent);

        const $titleInputCheckboxParent = $clonedTemplateContent.find('.func-title-input-checkbox-parent');
        if ($titleInputCheckboxParent.length) {
            $titleInputCheckboxParent.find('.func-title-input-checkbox').prop('checked', true);
            const selectedLanguage = $titleInputCheckboxParent.find('.language-select input[type=radio]:checked').val();
            $titleInputCheckboxParent.find(`.language-input input[data-language="${selectedLanguage}"]`).first().trigger("focus");
        }

        updateLanguageSelectNames($clonedTemplateContent);
        updateAllIndexes();
    });

    $document.on('click', '.js-delete-section-button', function () {
        const $this = $(this);
        $chapterWrapper = $this.closest('.chapter-wrapper');
        $sectionWrapper = $this.closest('.section-wrapper');

        const chapterIndex = $chapterWrapper.data('index');
        const sectionIndex = $sectionWrapper.data('index');

        $sectionWrapper.remove();

        const $chapterDraggableContainers = $chapterSortableContainer.find('>.draggable-container');
        const $chapterDraggableContainer = $($chapterDraggableContainers[chapterIndex]);
        const sectionDraggableContainer = $chapterDraggableContainer.find('.section-sortable-container > .draggable-container')[sectionIndex];
        sectionDraggableContainer.remove();

        updateAllIndexes();
    });

    $document.on('click', '.js-add-question-button', function () {
        const $this = $(this);
        const $clonedTemplateContent = $('#question-wrapper-template').contents().clone();
        $clonedTemplateContent.insertBefore($this);

        const $chapterWrapper = $this.closest('.chapter-wrapper');
        const $sectionWrapper = $this.closest('.section-wrapper');

        const chapterIndex = $chapterWrapper.data('index');
        const sectionIndex = $sectionWrapper.data('index');

        const $clonedDraggableTemplateContent = $('#question-draggable-container-template').contents().clone();
    
        const $chapterDraggableContainers = $chapterSortableContainer.find('>.draggable-container');
        const $chapterDraggableContainer = $($chapterDraggableContainers[chapterIndex]);
        const $sectionDraggableContainer = $($chapterDraggableContainer.find('.section-sortable-container > .draggable-container')[sectionIndex]);
        const $questionSortableContainer = $sectionDraggableContainer.find('.question-sortable-container');
        $questionSortableContainer.append($clonedDraggableTemplateContent);

        updateLanguageSelectNames($clonedTemplateContent);
        updateAllIndexes();
    });

    $document.on('click', '.js-delete-question-button', function () {
        const $this = $(this);
        const $chapterWrapper = $this.closest('.chapter-wrapper');
        const $sectionWrapper = $this.closest('.section-wrapper');
        const $questionWrapper = $this.closest('.question-wrapper');

        const chapterIndex = $chapterWrapper.data('index');
        const sectionIndex = $sectionWrapper.data('index');
        const questionIndex = $questionWrapper.data('index');

        $questionWrapper.remove();

        const $chapterDraggableContainers = $chapterSortableContainer.find('>.draggable-container');
        const $chapterDraggableContainer = $($chapterDraggableContainers[chapterIndex]);
        const $sectionDraggableContainer = $($chapterDraggableContainer.find('.section-sortable-container > .draggable-container')[sectionIndex]);
        const questionDraggableContainer = $sectionDraggableContainer.find('.question-sortable-container > .draggable-container')[questionIndex];
        questionDraggableContainer.remove();

        updateAllIndexes();
    });

    $document.on('click', '.js-add-answer-button', function () {
        const $this = $(this);
        const templateId = $this.data('templateId');
        const $clonedTemplateContent = $(`#${templateId}`).contents().clone();
        const $answerSortableContainer = $this.closest('.question-content-wrapper').find('.answer-sortable-container');
        $answerSortableContainer.append($clonedTemplateContent);
        updateLanguageSelectNames($clonedTemplateContent);
        updateAnswerIndexes($this.closest('.question-wrapper'));
    });

    $document.on('click', '.js-delete-answer-button', function () {
        const $this = $(this);
        $(this).closest('.answer-wrapper').remove();
        updateAnswerIndexes($this.closest('.question-wrapper'));
    });

    $document.on('change', '.js-question-type-select', function () {
        $this = $(this);
        const $selectedOption = $this.find(':selected');
        const templateId = $selectedOption.data('templateId');
        const $clonedTemplateContent = $(`#${templateId}`).contents().clone();
        const $questionWrapper = $this.closest('.question-wrapper');
        const $questionContentWrapper = $questionWrapper.find('.question-content-wrapper');
        $questionContentWrapper.empty().append($clonedTemplateContent);
        
        updateAnswerIndexes($questionWrapper);

        const $sortableContainers = $questionContentWrapper.find('.answer-sortable-container');
        if ($sortableContainers.length)
            $sortableContainers.each(function (i, sortableContainer) {
                createSortable(sortableContainer, (evt) => {
                    updateAnswerIndexes($questionWrapper);
                });
            });
    });

    /**
     * Sortable functions
     */

    createSortable($chapterSortableContainer[0], (sortableEvent) => {
        const { item } = sortableEvent;
        const $item = $(item);
        const oldIndex = Number(item.dataset.index);
        $item.closest('.chapter-sortable-container').find('>.draggable-container').each(function (i, draggableContainer) {
            draggableContainer.dataset.index = i;
        });
        const newIndex = Number(item.dataset.index);
        if (newIndex === oldIndex) return;
        const $chapterWrappers = $createSurveyForm.find('.chapter-wrapper');
        if (newIndex === $chapterWrappers.length - 1)
            $createSurveyForm.append($chapterWrappers[oldIndex]);
        else {
            const insertThis = $chapterWrappers[oldIndex];
            const indexToInsert = newIndex + (newIndex > oldIndex ? 1 : 0);
            const beforeThis = $chapterWrappers[indexToInsert];
            beforeThis.before(insertThis);
        }

        updateAllIndexes();
    });

    const $sectionSortableContainers = $('.section-sortable-container');
    $sectionSortableContainers.each(function (i, sectionSortableContainer) {
        createSortable(sectionSortableContainer, (sortableEvent) => {
            const { from, item, to } = sortableEvent;
            const oldIndex = Number(item.dataset.index);

            const $fromSectionContainer = $(from);
            const $toSectionContainer = $(to);

            $fromSectionContainer.find('>.draggable-container').each(function (i, draggableContainer) {
                draggableContainer.dataset.index = i;
            });
            $toSectionContainer.find('>.draggable-container').each(function (i, draggableContainer) {
                draggableContainer.dataset.index = i;
            });
            const newIndex = Number(item.dataset.index);

            const fromChapterIndex  = $fromSectionContainer.closest('.draggable-container').data('index');
            const toChapterIndex  = $toSectionContainer.closest('.draggable-container').data('index');

            if (oldIndex === newIndex && fromChapterIndex === toChapterIndex) return;

            const $chapterWrappers = $createSurveyForm.find('.chapter-wrapper');
            const $fromChapterWrapper = $($chapterWrappers[fromChapterIndex]);
            const insertThis = $fromChapterWrapper.find('.section-wrapper')[oldIndex];
            const $toChapterWrapper = $($chapterWrappers[toChapterIndex]);
            const $sectionWrappers = $toChapterWrapper.find('.section-wrapper');

            if (newIndex === $sectionWrappers.length) {
                $toChapterWrapper.find('.js-add-section-button').before(insertThis);
            } else {
                $sectionWrappers[newIndex].before(insertThis);
            }

            updateAllIndexes();
        });
    });

    const $questionSortableContainers = $('.question-sortable-container');
    $questionSortableContainers.each(function (i, questionSortableContainer) {
        createSortable(questionSortableContainer, (sortableEvent) => {
            const { from, item, to } = sortableEvent;
            const oldIndex = Number(item.dataset.index);

            const $fromQuestionContainer = $(from);
            const $toQuestionContainer = $(to);

            $fromQuestionContainer.find('>.draggable-container').each(function (i, draggableContainer) {
                draggableContainer.dataset.index = i;
            });
            $toQuestionContainer.find('>.draggable-container').each(function (i, draggableContainer) {
                draggableContainer.dataset.index = i;
            });
            const newIndex = Number(item.dataset.index);

            const $fromSectionContainer = $fromQuestionContainer.closest('.draggable-container');
            const $toSectionContainer = $toQuestionContainer.closest('.draggable-container');
            const fromSectionIndex  = $fromSectionContainer.data('index');
            const toSectionIndex  = $toSectionContainer.data('index');

            const $fromChapterContainer = $fromSectionContainer.parent().closest('.draggable-container');
            const $toChapterContainer = $toSectionContainer.parent().closest('.draggable-container');
            const fromChapterIndex  = $fromChapterContainer.data('index');
            const toChapterIndex  = $toChapterContainer.data('index');

            if (oldIndex === newIndex && fromChapterIndex === toChapterIndex && fromSectionIndex === toSectionIndex) return;

            const $chapterWrappers = $createSurveyForm.find('.chapter-wrapper');
            const $fromChapterWrapper = $($chapterWrappers[fromChapterIndex]);
            const $fromSectionWrapper = $($fromChapterWrapper.find('.section-wrapper')[fromSectionIndex]);
            const insertThis = $fromSectionWrapper.find('.question-wrapper')[oldIndex];

            const $toChapterWrapper = $($chapterWrappers[toChapterIndex]);
            const $toSectionWrapper = $($toChapterWrapper.find('.section-wrapper')[toSectionIndex]);
            const $questionWrappers = $toSectionWrapper.find('.question-wrapper');

            if (newIndex === $questionWrappers.length) {
                $toSectionWrapper.find('.js-add-question-button').before(insertThis);
            } else {
                $questionWrappers[newIndex].before(insertThis);
            }

            updateAllIndexes();
        });
    });

    const $answerSortableContainers = $('.answer-sortable-container');
    $answerSortableContainers.each(function (i, answerSortableContainer) {
        createSortable(answerSortableContainer, (sortableEvent) => {
            const { from, to } = sortableEvent;
            if (from !== to)
                updateAllIndexes();
            else
                updateAnswerIndexes($(sortableEvent.item).closest('.question-wrapper'));
        });
    });
}
