import $ from 'jquery';

const $allInputsToBeUnchecked = $('.js-uncheck-all-parent input[type=checkbox]:not(.js-uncheck-all)')
const $inputUncheckAll = $('.js-uncheck-all')

$inputUncheckAll.on('change', function () {
    if (!this.checked)
        $allInputsToBeUnchecked.prop('checked', false);
})


$('.js-set-checkbox-to-checked').on('click', function () {
    $(this.dataset.target).prop('checked', true);
});

$('.js-checkbox-to-my-checked').on('click', function () {
    // console.log($(this.dataset.target), this.checked);
    const $target = $(this.dataset.target)
    $target.prop('checked', this.checked);
    $target.trigger('change');
});


$('.menu-checkbox, .func-dropdown-checkbox-0').on('change', function () {
    document.cookie = `checkbox_${this.name}=${this.checked}; path=/`
});

// these are all radio and checkboxes onchange handlers:
$('.js-check-radio-table input').on('change', function (e) {
    // DO NOT PREVENT DEFAULT!!
    const $input = $(this);
    const inputType = $input.attr('type');
    const $row = $input.closest('tr');
    const $radios = $row.find('input[type=radio]');

    if (inputType === 'checkbox')
        handleCheckboxChange($input, $row, $radios);

});

$(document.body).on('click', '.js-check-radio-table label:has(input[type=radio][disabled])', function (e) {
    const $radio = $(this)
    const $row = $radio.closest('tr');
    const $checkbox = $row.find('input[type=checkbox]');
    const $radios = $row.find('input[type=radio]');

    if (!$checkbox.prop('checked')) {
        $checkbox.prop('checked', true);
        handleCheckboxChange($checkbox, $row, $radios);
    }
});

function handleCheckboxChange($checkbox, $row, $radios) {
    const isChecked = $checkbox.prop('checked');
    $row.toggleClass('disabled', !isChecked);
    $radios.prop('disabled', !isChecked);
}

// other checkbox, 
// -- NOTE: This one needs to be implemented in 'app/templates/visit/create.html.twig'
$('.js-enable-when-checked').on('change', function () {
    $(this.dataset.target).prop('disabled', !this.checked);
});
