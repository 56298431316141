import $ from 'jquery';
import Sortable from 'sortablejs';

$('.sortable-container.sortable-shared').each(function (i, item) {
    new Sortable(item, {
        group: 'shared',
        handle: '.fa-arrows-alt',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        // get sorting weights after drag and drop
        onEnd: function (evt) {
            // -- NOTE: ond end we want to update the sorting weights
            // -- we should keep track of this in a hidden input field

        }
    });
});

$('.sortable-container.sortable-nested').each(function (i, item) {
    new Sortable(item, {
        group: 'nested',
        handle: '.fa-arrows-alt',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        // get sorting weights after drag and drop
        onEnd: function (evt) {
            // -- NOTE: ond end we want to update the sorting weights
            // -- we should keep track of this in a hidden input field

        }
    });
});

// -- NOTE: we need to able to add an item to each the list using a button